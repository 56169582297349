<template>
	<div id="cpm_frieghtEdit" class="el-content">
		<a-form :label-col="{span:2}" :wrapper-col="{span:16}">
            <a-form-item label="排序">
                <a-input v-model:value="form.rank"></a-input>
            </a-form-item>
            <a-form-item label="规格名称">
                <a-input v-model:value="form.name"></a-input>
            </a-form-item>
            <a-form-item label="是否默认" >
                <a-radio-group v-model:value="form.is_default">
                    <a-radio :value="1">是</a-radio>
                    <a-radio :value="0">否</a-radio>
                </a-radio-group>
            </a-form-item>
            <a-form-item label="计费方式">
                <a-radio-group v-model:value="form.type">
                    <a-radio :value="1">按重计费</a-radio>
                    <a-radio :value="2">按件计费</a-radio>
                </a-radio-group>
            </a-form-item>
            <a-form-item label="配送区域" >
                <a-row>
                    <a-col :span="4"><div>运送到</div></a-col>
                    <a-col :span="3">
                        <div>
                            <span  v-if="form.type == 1">首重(克)</span>
                            <span  v-if="form.type == 2">首件(件)</span>
                        </div>
                    </a-col>
                    <a-col :span="3">
                        <div>
                            <span  v-if="form.type == 1">首重(元)</span>
                            <span  v-if="form.type == 2">首件(元)</span>
                        </div>
                    </a-col>
                    <a-col :span="3">
                        <div>
                            <span  v-if="form.type == 1">续重(克)</span>
                            <span  v-if="form.type == 2">续件(件)</span>
                        </div>
                    </a-col>
                    <a-col :span="3">
                        <div>
                            <span  v-if="form.type == 1">续重(元)</span>
                            <span  v-if="form.type == 2">续件(元)</span>
                        </div>
                    </a-col>
                    <a-col :span="4"><div>邮费满额包邮(元)</div></a-col>
                    <a-col :span="2"><div>管理</div></a-col>
                </a-row>
                
                <a-row v-for="(item,index) in spec" :key="index" style="margin-bottom: 20px;">
                    <a-col :span="4">
                        <div v-if="item.city_ids == 0 ">全国 [默认运费]</div>
                        <div v-else>
                            <span style="font-size: 12px;" v-for="(v,i) in item.city_ids_name" :key="i">{{v}};</span>
                            <span style="cursor: pointer;" @click="addNewRegion(index)">[编辑]</span>
                        </div>
                    </a-col>
                    <a-col :span="3">
                        <div>
                            <a-input v-model:value="item.first"></a-input>
                        </div>
                    </a-col>
                    <a-col :span="3">
                        <div>
                            <a-input v-model:value="item.first_price"></a-input>
                        </div>
                    </a-col>
                    <a-col :span="3">
                        <div>
                            <a-input v-model:value="item.second"></a-input>
                        </div>
                    </a-col>
                    <a-col :span="3">
                        <div>
                            <a-input v-model:value="item.second_price"></a-input>
                        </div>
                    </a-col>
                    <a-col :span="4">
                        <div>
                            <a-input v-model:value="item.price"></a-input>
                        </div>
                    </a-col>
                    <a-col :span="2">
                        <div>
                            <a-button v-if="item.city_ids != 0 " @click="deleteRegion(index)"><i class="ri-delete-bin-line"></i></a-button>
                        </div>
                    </a-col>
                </a-row>
                <a-button @click="addNewRegion(-1)"><i class="ri-add-line"></i>新增配送区域</a-button>
                
            </a-form-item>
            
            <a-form-item label="不配送区域">
                <span style="font-size: 12px;" v-for="(v,i) in no_checkListName" :key="i">{{v}};</span>
                <a-button @click="addNewRegion(-2)"><i class="ri-add-line"></i>选择不配送地区</a-button>
            </a-form-item>
            <a-form-item :wrapper-col="{offset:2}">
                <a-space>
                    <a-button type="primary" @click="submitSave">保存</a-button>
                    <a-button @click="$router.go(-1)">返回</a-button>
                </a-space>
            </a-form-item>
        </a-form>
		<a-modal v-model:visible="regionShow" title="选择区域" @ok="getCheckName" @cancel="regionShow = false" width="800px">
            <div class="region">
                <a-checkbox-group v-model:value="checkList">
                    <div class="region-ul" v-for="(item,index) in city" :key="index">
                        <div class="region_name">
							<a-checkbox :disabled="item.is_readonly" :value="item.name" @change="checkAll(item.name)">{{item.name}}</a-checkbox>
						</div>
                        <div class="ul_li">
							<div class="region-li" v-for="(items,indexs) in item.group" :key="indexs">
								<div class="li-parent">
									<a-checkbox :disabled="items.is_readonly" :value="items.id"  @change="checkItems(items.id)">{{items.name}}</a-checkbox>
								</div>
								<div class="li_child">
									<a-checkbox :disabled="val.is_readonly" :value="val.id" v-for="(val,ind) in items.group" :key="ind" class="checkbox-items">{{val.name}}</a-checkbox>
								</div>
							</div>
						</div>
                    </div>
                </a-checkbox-group>  
			</div>
        </a-modal>
	</div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import setModel from '@/api/set'
import commonModel from '@/api/common'
import { useRoute } from 'vue-router'
import tool from '@/util/tool.js'
export default{
	name:'frieght-list',
	setup(){
		const state = reactive({
			id:0,
			form:{
				id:0,
				name:'',
				type:1,
				no_distribution:[],
				is_default:0,
				rank:99,
			},
			spec:[
				{ city_ids:0, first:'',first_price:'',second:'',second_price:'',price:'',city_ids_name:['全国 [默认运费]'] }
			],
			regionShow:false,
			city:[],
			checkList:[],
			checkListName:[],
			spec_index:-1,
			no_checkListName:[],
		})
		const opt = useRoute().query

		setModel.getCity(res=>{
			state.city= res
			if( opt.id ){
				setModel.getFreightDetail(opt.id,res=>{
					state.spec = res.spec
					state.form = tool.filterForm(Object.keys(state.form),res)
					state.spec.map((item,index)=>{
						if( item.city_ids != 0 ){
							item.city_ids_name = getSpecName(item.city_ids)
						}
					})
					state.no_checkListName = getSpecName(res.no_distribution)
				})
			}
		})

		function submitSave(){
			let form =JSON.parse(JSON.stringify(state.form))
			let spec =JSON.parse(JSON.stringify(state.spec))
			setModel.setFreight(form,spec)
		}

		function checkAll(id){
			let { city } = state
			let timer = setTimeout(function(){  //延时执行
				clearTimeout(timer)
				for (var i = 0; i < city.length; i++) {
					if( city[i].name == id && city[i].group ){
						let id_index = getIndex(state.checkList,id )
						if ( id_index == -1 ){
							recursionCity( city[i].group,-1 )
						}else{
							recursionCity( city[i].group,-2 )
						}
						return
					}
				}
				
			},500)
		}

		//选择二级
		function checkItems(id){
			let { city } = state
			let timer = setTimeout(function(){
				clearTimeout(timer)
				for (var i = 0; i < city.length; i++) {
					// 递归这一部分
					recursionCity(city[i].group,id)
				}
			},300)
			
		}

		function recursionCity( group,id ){
			let { city } = state
			//二级城市
			if( !group && group ==undefined ){
				return
			}
			for (var j = 0; j < group.length; j++) {
				//选择顶级时，直接递归
				if( id == -1 && state.checkList ){
					state.checkList.splice(getIndex( state.checkList,group[j].id) ,1)
					recursionCity(group[j].group,-1 )
				}
				
				if( id == -2 ){  //push 新选择地区时
					if( !group[j].is_readonly ){
						state.checkList.push( group[j].id )	
					}
					recursionCity(group[j].group,-2 )
				}
				
				if( group[j].id == id ){
					let id_index = getIndex( state.checkList,id )
					if( id_index == -1 ){
						recursionCity(group[j].group,-1 )
					}else{
						recursionCity(group[j].group,-2)
					}
				}
			}
			
		}

		function getIndex(arr,str){
			if( !arr || arr==undefined ||arr.length == 0){
				return -1
			}
			return arr.findIndex(function(value, index, arr) {
				return value == str
			})
		}

		//获取选中的地区的中文名称
		function getSpecName(checkList){
			let { city } = state , checkListName = []
			if(!checkList || checkList.length == 0  || checkList == 0){
				return
			}
			city.map(item=>{
				if( getIndex( checkList, item.name ) >=0 ){
					checkListName.push(item.name)
				}
				
				if( item.group && item.group!=undefined ){
					
					item.group.map(v=>{
						
						if( getIndex( checkList, v.id ) >=0 ){
							checkListName.push( v.name )	
						}
						v.group.map(v_item=>{
							if( getIndex( checkList, v_item.id ) >=0 ){
								checkListName.push( v_item.name ) 
							}
						})
					})
				}
			})
			return checkListName ;
		}

		//确认选择地址
		function getCheckName(){
			let { city ,checkList,spec_index } = state
			if( checkList.length == 0 || !checkList ){
				tool.message("请选择地区");return
			}
			let checkListName = getSpecName(checkList);
			if( spec_index == -2 ){
				state.form.no_distribution = checkList
				state.no_checkListName = checkListName
			}else if ( spec_index == -1 ){
				let spec = {
					city_ids:checkList, 
					first:'',
					first_price:'',
					second:'',
					second_price:'',
					price:'',
					city_ids_name:checkListName ,
				}
				
				state.spec.push(spec)
			}else{
				state.spec[spec_index].city_ids = checkList
				state.spec[spec_index].city_ids_name = checkListName
			}
			state.spec_index = -1
			state.regionShow = false
		}

		 // index -1新增地址 other 编辑checkList 下标
		function addNewRegion(index){
			let { city , spec } = state ,checkIds = []
			state.spec_index = index
			if( index != -2 ){
				spec.map((item,ind)=>{
					if( item.city_ids!= 0 && ind !=index ){
						item.city_ids.map(v=>{
							checkIds.push(v)
						})
					}
				}) 
				state.checkList = index == -1 ? [] : spec[index].city_ids
			}
			
			if( index == -2){
				state.checkList = state.form.no_distribution || []
			}
			oprationCity(checkIds);
			
			state.regionShow = true
		}

		//将city 中已经选择的地址设置为disabled
		function oprationCity(checkIds){
			let { city } = state
			city.map((item,index)=>{
				city[index].is_readonly = false
				if( getIndex( checkIds, item.name ) >=0 ){
					city[index].is_readonly = true
				}
				
				if( item.group && item.group!=undefined ){
					item.group.map((v,i)=>{
						city[index].group[i].is_readonly = false
						if( getIndex( checkIds, v.id ) >=0 ){
							city[index].group[i].is_readonly = true
							v.group.map((v_item,v_index)=>{
								city[index].group[i].group[v_index].is_readonly = false
								if( getIndex(checkIds,v_item.id ) >=0 ){
									city[index].group[i].group[v_index].is_readonly = true
								}
							})
						}else{
							if( v.group ){
								v.group.map((v_item,v_index)=>{
									city[index].group[i].group[v_index].is_readonly = false
									if( getIndex(checkIds,v_item.id ) >=0 ){
										city[index].group[i].group[v_index].is_readonly = true
									}
								})
							}
						}
					})
				}
			})
			state.city = city
		}

		function deleteRegion(index){
			let{ spec } = state ,pk = spec[index].id || 0 
			if( pk ==0 ){
				state.spec.splice(index,1)
				return
			}
			commonModel.deleteDataList(pk,35,"确认删除这条信息吗？").then(()=>state.spec.splice(index,1))
		}

		return{
			...toRefs(state),
			submitSave,
			checkAll,
			checkItems,
			getIndex,
			recursionCity,
			getSpecName,
			getCheckName,
			addNewRegion,
			oprationCity,
			deleteRegion
		}
	}
	
}
</script>

<style lang="scss">
	.region{
		width: 100%;
	}
	.ul_li{
		width: 94%;
		margin: auto;
		display: flex;
		flex-wrap: wrap;
	}
	.region-li{
		margin: 0 10px 20px 0;
	}
	.li-parent{
		border: 2px solid  #FFFFFF;
		padding: 0 10px 0 10px;
	}
	.li_child{
		display: none;
		position: absolute;
		height: auto;
		width: 350px;
		padding: 10px 0 0 10px;
		z-index: 999;
	}
	.region-li:hover .li-parent{
		border: 2px solid  #94c6ff;
		background: #eef5ff;
		border-bottom: none;
	}
	.region-li:hover .li_child{
		display: block!important;
		border: 2px solid  #94c6ff;
		background: #eef5ff;
	}
	.checkbox-items{
		margin-bottom: 10px;
	}
	
</style>
